import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import NoAccess from '../views/NoAccess.vue'
import Login from '../views/Login.vue'
import LedgerMain from '../views/ledgers/Main.vue'
import LedgerList from '../views/ledgers/List.vue'
import LedgerForm from '../views/ledgers/Form.vue'
import BearingMain from '../views/bearings/Main.vue'
import BearingList from '../views/bearings/List.vue'
import BearingForm from '../views/bearings/Form.vue'
import SegmentMain from '../views/segments/Main.vue'
import SegmentList from '../views/segments/List.vue'
import SegmentForm from '../views/segments/Form.vue'
import ErectionMain from '../views/erections/Main.vue'
import ErectionList from '../views/erections/List.vue'
import ErectionForm from '../views/erections/Form.vue'
import JobMain from '../views/jobs/Main.vue'
import JobList from '../views/jobs/List.vue'
import JobForm from '../views/jobs/Form.vue'
import CodeMain from '../views/codes/Main.vue'
import CodeForm from '../views/codes/Form.vue'
import CodeList from '../views/codes/List.vue'
import ReportMain from '../views/reports/Main.vue'
import ReportList from '../views/reports/List.vue'
import SiteOperationReport from '../views/reports/SiteOperationReport.vue'
import SiteBudgetReport from '../views/reports/SiteBudgetReport.vue'
import LGBudgetReport from '../views/reports/LGBudgetReport.vue'
import YardBudgetReport from '../views/reports/YardBudgetReport.vue'
import J2369AnnualReport from '../views/reports/J2369AnnualReport.vue'
import ManpowerCostByLGReport from '../views/reports/ManpowerCostByLGReport.vue'
import EmployeeMain from '../views/employees/Main.vue'
import EmployeeList from '../views/employees/List.vue'
import EmployeeForm from '../views/employees/Form.vue'
import AccessoryMain from '../views/accessory/Main.vue'
import AccessoryList from '../views/accessory/List.vue'
import AccessoryForm from '../views/accessory/Form.vue'
import StoreMain from '../views/stores/Main.vue'
import StoreList from '../views/stores/List.vue'
import StoreForm from '../views/stores/Form.vue'
import StockMain from '../views/stocks/Main.vue'
import StockList from '../views/stocks/List.vue'
import StockForm from '../views/stocks/Form.vue'
import StockSummary from '../views/stocks/Summary.vue'
import BudgetAgreementMain from '../views/budgetAgreements/Main.vue'
import BudgetAgreementForm from '../views/budgetAgreements/Form.vue'
import BudgetAgreementList from '../views/budgetAgreements/List.vue'
import BudgetAgreementJob from '../views/budgetAgreements/Job.vue'
import EquipmentMain from '../views/equipment/Main.vue'
import EquipmentList from '../views/equipment/List.vue'
import EquipmentForm from '../views/equipment/Form.vue'
import EquipmentSummary from '../views/equipment/Summary.vue'
import SteelSheetMain from '../views/steelSheet/Main.vue'
import SteelSheetList from '../views/steelSheet/List.vue'
import SteelSheetForm from '../views/steelSheet/Form.vue'
import SteelSheetSummary from '../views/steelSheet/Summary.vue'
import ToolMain from '../views/tool/Main.vue'
import ToolList from '../views/tool/List.vue'
import ToolForm from '../views/tool/Form.vue'
import MaintenanceMain from '../views/maintenance/Main.vue'
import MaintenanceList from '../views/maintenance/List.vue'
import MaintenanceForm from '../views/maintenance/Form.vue'
import WorkMain from '../views/works/Main.vue'
import WorkList from '../views/works/List.vue'
import WorkForm from '../views/works/Form.vue'
import SubcontractorMain from '../views/subcontractors/Main.vue'
import SubcontractorList from '../views/subcontractors/List.vue'
import SubcontractorForm from '../views/subcontractors/Form.vue'
import PINMain from '../views/pin/Main.vue'
import PINList from '../views/pin/List.vue'
import PINForm from '../views/pin/Form.vue'
import PINSummary from '../views/pin/Summary.vue'
import PTBarMain from '../views/ptbar/Main.vue'
import PTBarList from '../views/ptbar/List.vue'
import PTBarForm from '../views/ptbar/Form.vue'
import PTBarSummary from '../views/ptbar/Summary.vue'
import JackMain from '../views/jacks/Main.vue'
import JackList from '../views/jacks/List.vue'
import JackForm from '../views/jacks/Form.vue'
import JackSummary from '../views/jacks/Summary.vue'
import ProgressMain from '../views/progress/Main.vue'
import ProgressList from '../views/progress/List.vue'
import ProgressForm from '../views/progress/Form.vue'
import ProgressDetail from '../views/progress/Detail.vue'
import ProgressSummaryList from '../views/progress/SummaryList.vue'
import ProgressSummary from '../views/progress/Summary.vue'
import AccommodationMain from '../views/accommodations/Main.vue'
import AccommodationLayout from '../views/accommodations/Layout.vue'
import AccommodationForm from '../views/accommodations/Form.vue'
import AccommodationList from '../views/accommodations/List.vue'
import ManpowerMain from '../views/manpowers/Main.vue'
import ManpowerList from '../views/manpowers/List.vue'
import ManpowerForm from '../views/manpowers/Form.vue'
import ManpowerSummary from '../views/manpowers/Summary.vue'

import DrawingMain from '../views/drawings/Main.vue'
import DrawingList from '../views/drawings/List.vue'
import DrawingForm from '../views/drawings/Form.vue'
import DrawingJob from '../views/drawings/Job.vue'
import PierSchedulesMain from '../views/pierschedules/Main.vue'
import PierSchedulesList from '../views/pierschedules/List.vue'
import PierSchedulesForm from '../views/pierschedules/Form.vue'
import PierSchedulesJob from '../views/pierschedules/Job.vue'
import SubmissionMain from '../views/submissions/Main.vue'
import SubmissionList from '../views/submissions/List.vue'
import SubmissionForm from '../views/submissions/Form.vue'
import SubmissionJob from '../views/submissions/Job.vue'
import insuranceMain from '../views/insurances/Main.vue'
import insuranceList from '../views/insurances/List.vue'
import insuranceForm from '../views/insurances/Form.vue'
import insuranceJob from '../views/insurances/Job.vue'
import SupplierMain from '../views/suppliers/Main.vue'
import SupplierList from '../views/suppliers/List.vue'
import SupplierForm from '../views/suppliers/Form.vue'
import SupplierJob from '../views/suppliers/Job.vue'
import LGSpecificationMain from '../views/lgSpecifications/Main.vue'
import LGSpecificationList from '../views/lgSpecifications/List.vue'
import LGSpecificationForm from '../views/lgSpecifications/Form.vue'
import LGSpecificationJob from '../views/lgSpecifications/Job.vue'

import PresentationMain from '../views/presentations/Main.vue'
import PresentationList from '../views/presentations/List.vue'
import PresentationForm from '../views/presentations/Form.vue'
import PresentationJob from '../views/presentations/Job.vue'

import MonthlyProgressMain from '../views/monthlyProgresses/Main.vue'
import MonthlyProgressList from '../views/monthlyProgresses/List.vue'
import MonthlyProgressForm from '../views/monthlyProgresses/Form.vue'
import MonthlyProgressJob from '../views/monthlyProgresses/Job.vue'

import UserMain from '../views/users/Main.vue'
import UserList from '../views/users/List.vue'
import UserForm from '../views/users/Form.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/user',
    name: 'UserMain',
    component: UserMain,
    children: [
      {
        path: '',
        name: 'UserList',
        component: UserList
      },
      {
        path: '/user/form',
        name: 'UserForm',
        component: UserForm
      },
      {
        path: '/user/edit/:id',
        name: 'UserEdit',
        component: UserForm
      }
    ]
  },
  {
    path: '/cost',
    name: 'LedgerMain',
    component: LedgerMain,
    children: [
      {
        path: '',
        name: 'LedgerList',
        component: LedgerList
      },
      {
        path: '/cost/form',
        name: 'LedgerForm',
        component: LedgerForm
      },
      {
        path: '/cost/edit/:id',
        name: 'LedgerEdit',
        component: LedgerForm
      }
    ]
  },
  {
    path: '/bearing',
    name: 'BearingMain',
    component: BearingMain,
    children: [
      {
        path: '',
        name: 'BearingList',
        component: BearingList
      },
      {
        path: '/bearing/form',
        name: 'BearingForm',
        component: BearingForm
      },
      {
        path: '/bearing/edit/:id',
        name: 'BearingEdit',
        component: BearingForm
      }
    ]
  },
  {
    path: '/segment',
    name: 'SegmentMain',
    component: SegmentMain,
    children: [
      {
        path: '',
        name: 'SegmentList',
        component: SegmentList
      },
      {
        path: '/segment/form',
        name: 'SegmentForm',
        component: SegmentForm
      },
      {
        path: '/segment/edit/:id',
        name: 'SegmentEdit',
        component: SegmentForm
      }
    ]
  },
  {
    path: '/erection',
    name: 'ErectionMain',
    component: ErectionMain,
    children: [
      {
        path: '',
        name: 'ErectionList',
        component: ErectionList
      },
      {
        path: '/erection/form',
        name: 'ErectionForm',
        component: ErectionForm
      },
      {
        path: '/erection/edit/:id',
        name: 'ErectionEdit',
        component: ErectionForm
      }
    ]
  },
  {
    path: '/job',
    name: 'JobMain',
    component: JobMain,
    children: [
      {
        path: '',
        name: 'JobList',
        component: JobList
      },
      {
        path: '/job/form',
        name: 'JobForm',
        component: JobForm
      },
      {
        path: '/job/edit/:id',
        name: 'JobEdit',
        component: JobForm
      }
    ]
  },
  {
    path: '/code',
    name: 'CodeMain',
    component: CodeMain,
    children: [
      {
        path: '',
        name: 'CodeList',
        component: CodeList
      },
      {
        path: '/code/form',
        name: 'CodeForm',
        component: CodeForm
      },
      {
        path: '/code/edit/:id',
        name: 'CodeEdit',
        component: CodeForm
      }
    ]
  },
  {
    path: '/report',
    name: 'ReportMain',
    component: ReportMain,
    children: [
      {
        path: '',
        name: 'ReportList',
        component: ReportList
      },
      {
        path: '/report/pos/:j',
        name: 'SiteBudgetReport By Job',
        component: SiteBudgetReport
      },
      {
        path: '/report/site',
        name: 'SiteOperationReport',
        component: SiteOperationReport
      },
      {
        path: '/report/lg',
        name: 'LGBudgetReport',
        component: LGBudgetReport
      },
      {
        path: '/report/yard/:j',
        name: 'YardBudgetReport By Job',
        component: YardBudgetReport
      },
      {
        path: '/report/annual',
        name: ' J2369AnnualReport',
        component: J2369AnnualReport
      },
      {
        path: '/report/3',
        name: 'SiteBudgetReport',
        component: SiteBudgetReport
      },
      {
        path: '/report/manpower-by-lg',
        name: 'ManPowerCostByLGReport',
        component: ManpowerCostByLGReport
      }
    ]
  },
  {
    path: '/personnel',
    name: 'EmployeeMain',
    component: EmployeeMain,
    children: [
      {
        path: '',
        name: 'EmployeeList',
        component: EmployeeList
      },
      {
        path: '/employee/form',
        name: 'EmployeeForm',
        component: EmployeeForm
      },
      {
        path: '/employee/edit/:id',
        name: 'EmployeeEdit',
        component: EmployeeForm
      }
    ]
  },
  {
    path: '/accessory',
    name: 'AccessoryMain',
    component: AccessoryMain,
    children: [
      {
        path: '',
        name: 'AccessoryList',
        component: AccessoryList
      },
      {
        path: '/accessory/form',
        name: 'AccessoryForm',
        component: AccessoryForm
      },
      {
        path: '/accessory/edit/:id',
        name: 'AccessoryEdit',
        component: AccessoryForm
      }
    ]
  },
  {
    path: '/store',
    name: 'StoreMain',
    component: StoreMain,
    children: [
      {
        path: '',
        name: 'StoreList',
        component: StoreList
      },
      {
        path: '/store/form',
        name: ' StoreForm',
        component: StoreForm
      },
      {
        path: '/store/edit/:id',
        name: 'StoreEdit',
        component: StoreForm
      }
    ]
  },
  {
    path: '/stock',
    name: 'StockMain',
    component: StockMain,
    children: [
      {
        path: '',
        name: 'StockList',
        component: StockList
      },
      {
        path: '/stock/form',
        name: ' StockForm',
        component: StockForm
      },
      {
        path: '/stock/edit/:id',
        name: 'StockEdit',
        component: StockForm
      },
      {
        path: '/stock/summary',
        name: 'StockSummary',
        component: StockSummary
      },
      
    ]
  },
  {
    path: '/work',
    name: 'WorkMain',
    component: WorkMain,
    children: [
      {
        path: '',
        name: 'WorkList',
        component: WorkList
      },
      {
        path: '/work/form',
        name: ' WorkForm',
        component: WorkForm
      },
    ]
  },

  {
    path: '/budget-agreement',
    name: 'BudgetAgreementMain',
    component: BudgetAgreementMain,
    children: [
      {
        path: '/budget-agreement/j/:job',
        name: 'BudgetAgreementList',
        component: BudgetAgreementList
      },
      {
        path: '/budget-agreement/form',
        name: 'BudgetAgreementForm',
        component: BudgetAgreementForm
      },
      {
        path: '/budget-agreement/edit/:id',
        name: 'BudgetAgreementEdit',
        component: BudgetAgreementForm
      },
      {
        path: '/budget-agreement/j',
        name: 'BudgetAgreementJob',
        component: BudgetAgreementJob
      }
    ]
  },
  {
    path: '/equipment',
    name: 'EquipmentMain',
    component: EquipmentMain,
    children: [
      {
        path: '',
        name: 'EquipmentList',
        component: EquipmentList
      },
      {
        path: '/equipment/form',
        name: 'EquipmentForm',
        component: EquipmentForm
      },
      {
        path: '/equipment/edit/:id',
        name: 'EquipmentEdit',
        component: EquipmentForm
      }, {
        path: '/equipment/summary',
        name: 'EquipmentSummary',
        component: EquipmentSummary
      }
    ]
  },
  {
    path: '/steelSheet',
    name: 'SteelSheetMain',
    component: SteelSheetMain,
    children: [
      {
        path: '',
        name: 'SteelSheetList',
        component: SteelSheetList
      },
      {
        path: '/steelSheet/form',
        name: 'SteelSheetForm',
        component: SteelSheetForm
      },
      {
        path: '/steelSheet/edit/:id',
        name: 'SteelSheetEdit',
        component: SteelSheetForm
      }, {
        path: '/steelSheet/summary',
        name: 'SteelSheetSummary',
        component: SteelSheetSummary
      }
    ]
  },
  {
    path: '/tool',
    name: 'ToolMain',
    component: ToolMain,
    children: [
      {
        path: '',
        name: 'ToolList',
        component: ToolList
      },
      {
        path: '/tool/form',
        name: 'ToolForm',
        component: ToolForm
      },
      {
        path: '/tool/edit/:id',
        name: 'ToolEdit',
        component: ToolForm
      }
    ]
  },
  {
    path: '/maintenance',
    name: 'MaintenanceMain',
    component: MaintenanceMain,
    children: [
      {
        path: '',
        name: 'MaintenanceList',
        component: MaintenanceList
      },
      {
        path: '/maintenance/form',
        name: 'MaintenanceForm',
        component: MaintenanceForm
      },
      {
        path: '/maintenance/edit/:id',
        name: 'MaintenanceEdit',
        component: MaintenanceForm
      }
    ]
  },
  {
    path: '/subcontractor',
    name: 'SubcontractorMain',
    component: SubcontractorMain,
    children: [
      {
        path: '',
        name: 'SubcontractorList',
        component: SubcontractorList
      },
      {
        path: '/subcontractor/form',
        name: 'SubcontractorForm',
        component: SubcontractorForm
      },
      {
        path: '/subcontractor/edit/:id',
        name: 'SubcontractorEdit',
        component: SubcontractorForm
      }
    ]
  },
  {
    path: '/work',
    name: 'WorkMain',
    component: WorkMain,
    children: [
      {
        path: '',
        name: 'WorkList',
        component: WorkList
      },
      {
        path: '/work/form',
        name: 'WorkForm',
        component: WorkForm
      },
      {
        path: '/work/edit/:id',
        name: 'WorkEdit',
        component: WorkForm
      }
    ]
  },
  {
    path: '/pin',
    name: 'PINMain',
    component: PINMain,
    children: [
      {
        path: '',
        name: 'pinList',
        component: PINList
      },
      {
        path: '/pin/form',
        name: 'PINForm',
        component: PINForm
      },
      {
        path: '/pin/edit/:id',
        name: 'PINEdit',
        component: PINForm
      },
      {
        path: '/pin/summary',
        name: 'PINSummary',
        component: PINSummary
      },
    ]
  },
  {
    path: '/ptbar',
    name: 'PTBarMain',
    component: PTBarMain,
    children: [
      {
        path: '',
        name: 'PTBarList',
        component: PTBarList
      },
      {
        path: '/ptbar/form',
        name: 'PTBarForm',
        component: PTBarForm
      },
      {
        path: '/ptbar/edit/:id',
        name: 'PTBarEdit',
        component: PTBarForm
      },
      {
        path: '/ptbar/summary',
        name: 'PTBarSummary',
        component: PTBarSummary
      },
    ]
  },
  {
    path: '/jack',
    name: 'JackMain',
    component: JackMain,
    children: [
      {
        path: '',
        name: 'JackList',
        component: JackList
      },
      {
        path: '/jack/form',
        name: 'JackForm',
        component: JackForm
      },
      {
        path: '/jack/edit/:id',
        name: 'JackEdit',
        component: JackForm
      },
      {
        path: '/jack/summary',
        name: 'JackSummary',
        component: JackSummary
      }
    ]
  },
  {
    path: '/progress',
    name: 'ProgressMain',
    component: ProgressMain,
    children: [
      {
        path: '',
        name: 'ProgressList',
        component: ProgressList
      },
      {
        path: '/progress/lg/:lg',
        name: 'ProgressDetailForLG',
        component: ProgressDetail
      },
      {
        path: '/progress/detail',
        name: 'ProgressDetail',
        component: ProgressDetail
      },
      {
        path: '/progress/form',
        name: 'ProgressForm',
        component: ProgressForm
      },
      {
        path: '/progress/form/:lg',
        name: 'ProgressFormLG',
        component: ProgressForm
      },
      {
        path: '/progress/edit/:lg/:id',
        name: 'ProgressEditLG',
        component: ProgressForm
      },
      {
        path: '/progress/form/:name',
        name: 'ProgressFormWithName',
        component: ProgressForm
      },
      {
        path: '/progress/edit/:id',
        name: 'ProgressEdit',
        component: ProgressForm
      },
      {
        path: '/progress/summary/list',
        name: 'ProgressSummaryList',
        component: ProgressSummaryList
      },
      {
        path: '/progress/summary/j/:job',
        name: 'ProgressSummary',
        component: ProgressSummary
      }
    ]
  },
  {
    path: '/accommodation',
    name: 'AccommodationMain',
    component: AccommodationMain,
    children: [
      {
        path: '',
        name: 'AccommodationLayout',
        component: AccommodationLayout
      },
      {
        path: '/accommodation/list',
        name: 'AccommodationList',
        component: AccommodationList
      },
      {
        path: '/accommodation/form',
        name: 'AccommodationForm',
        component: AccommodationForm
      },
      {
        path: '/accommodation/edit/:id',
        name: 'AccommodationEdit',
        component: AccommodationForm
      }
    ]
  },
  {
    path: '/manpower',
    name: 'ManpowersMain',
    component: ManpowerMain,
    children: [
      {
        path: '',
        name: 'ManpowersList',
        component: ManpowerList
      },
      {
        path: '/manpower/form',
        name: 'ManpowersForm',
        component: ManpowerForm
      },
      {
        path: '/manpower/edit/:id',
        name: 'ManpowersEdit',
        component: ManpowerForm
      },
      {
        path: '/manpower/summary',
        name: 'ManpowersSummary',
        component: ManpowerSummary
      }
    ]
  },

  {
    path: '/drawing',
    name: 'DrawingMain',
    component: DrawingMain,
    children: [
      {
        path: '/drawing/j',
        name: 'DrawingJob',
        component: DrawingJob
      },
      {
        path: '/drawing',
        name: 'DrawingJob-b',
        component: DrawingJob
      },
      {
        path: '/drawing/j/:job',
        name: 'DrawingListJob',
        component: DrawingList
      },
      {
        path: '/drawing/form',
        name: 'DrawingForm',
        component: DrawingForm
      },
      {
        path: '/drawing/edit/:id',
        name: 'DrawingEdit',
        component: DrawingForm
      }
    ]
  },

  {
    path: '/msAndMat',
    name: 'MSAndMatMain',
    component: SubmissionMain,
    children: [
      {
        path: '/msAndMat/j',
        name: 'MSAndMatJob',
        component: SubmissionJob
      },
      {
        path: '/msAndMat/j/:job',
        name: 'MSAndMatListJob',
        component: SubmissionList
      },
      // {
      //   path: '',
      //   name: 'MSAndMatList',
      //   component: SubmissionList
      // },
      {
        path: '/msAndMat/form',
        name: 'MSAndMatForm',
        component: SubmissionForm
      },
      {
        path: '/msAndMat/edit/:id',
        name: 'MSAndMatEdit',
        component: SubmissionForm
      }
    ]
  },

  {
    path: '/lgspecifications',
    name: 'LGSpecificationMain',
    component: LGSpecificationMain,
    children: [
      {
        path: '/lgspecifications/lg',
        name: 'LGSpecificationJob',
        component: LGSpecificationJob
      },
      {
        path: '/lgspecifications',
        name: 'LGSpecificationJob-b',
        component: LGSpecificationJob
      },
      {
        path: '/lgspecifications/lg/:lg',
        name: 'LGSpecificationListJob',
        component: LGSpecificationList
      },
      {
        path: '/lgspecifications/form',
        name: 'LGSpecificationForm',
        component: LGSpecificationForm
      },
      {
        path: '/lgspecifications/edit/:id',
        name: 'LGSpecificationEdit',
        component: LGSpecificationForm
      }
    ]
  },

  {
    path: '/insurance',
    name: 'insuranceMain',
    component: insuranceMain,
    children: [
      {
        path: '/insurance/j',
        name: 'insuranceJob',
        component: insuranceJob
      },
      {
        path: '/insurance/j/:job',
        name: 'insuranceListJob',
        component: insuranceList
      },
      // {
      //   path: '',
      //   name: 'insuranceList',
      //   component: insuranceList
      // },
      {
        path: '/insurance/form',
        name: 'insuranceForm',
        component: insuranceForm
      },
      {
        path: '/insurance/edit/:id',
        name: 'insuranceEdit',
        component: insuranceForm
      }
    ]
  },

  {
    path: '/pierschedules',
    name: 'PierSchedulesMain',
    component: PierSchedulesMain,
    children: [
      {
        path: '/pierschedules/j',
        name: 'PierSchedulesJob',
        component: PierSchedulesJob
      },
      {
        path: '/pierschedules',
        name: 'PierSchedulesJob-b',
        component: PierSchedulesJob
      },
      {
        path: '/pierschedules/j/:job',
        name: 'PierSchedulesListJob',
        component: PierSchedulesList
      },
      {
        path: '/pierschedules/form',
        name: 'PierSchedulesForm',
        component: PierSchedulesForm
      },
      {
        path: '/pierschedules/edit/:id',
        name: 'PierSchedulesEdit',
        component: PierSchedulesForm
      }
    ]
  },


  {
    path: '/supplier',
    name: 'SupplierMain',
    component: SupplierMain,
    children: [
      {
        path: '/supplier/j',
        name: 'SupplierJob',
        component: SupplierJob
      },
      {
        path: '/supplier/j/:job',
        name: 'SupplierListJob',
        component: SupplierList
      },
      // {
      //   path: '',
      //   name: 'SupplierList',
      //   component: SupplierList
      // },
      {
        path: '/supplier/form',
        name: 'SupplierForm',
        component: SupplierForm
      },
      {
        path: '/supplier/edit/:id',
        name: 'SupplierEdit',
        component: SupplierForm
      }
    ]
  },
  {
    path: '/presentation',
    name: 'PresentationMain',
    component: PresentationMain,
    children: [
      {
        path: '/presentation/j',
        name: 'PresentationJob',
        component: PresentationJob
      },
      {
        path: '/presentation/j/:job',
        name: 'PresentationListJob',
        component: PresentationList
      },
      {
        path: '/presentation/form',
        name: 'PresentationForm',
        component: PresentationForm
      },
      {
        path: '/presentation/edit/:id',
        name: 'PresentationEdit',
        component: PresentationForm
      }
    ]
  },
  {
    path: '/monthlyProgress',
    name: 'MonthlyProgressMain',
    component: MonthlyProgressMain,
    children: [
      {
        path: '/monthlyProgress/j',
        name: 'MonthlyProgressJob',
        component: MonthlyProgressJob
      },
      {
        path: '/monthlyProgress/j/:job',
        name: 'MonthlyProgressListJob',
        component: MonthlyProgressList
      },
      {
        path: '/monthlyProgress/form',
        name: 'MonthlyProgressForm',
        component: MonthlyProgressForm
      },
      {
        path: '/monthlyProgress/edit/:id',
        name: 'MonthlyProgressEdit',
        component: MonthlyProgressForm
      }
    ]
  },
  {
    path: '/no-access',
    name: 'NoAccess',
    component: NoAccess
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

import store from "@/store/index"


router.beforeEach((to, from, next) => {
  console.log("store.state.accessLevel", store.state.accessLevel, to.path)
  if (to.path === '/login' || to.path === '/no-access') {
    next()
  }
  else if (store.state.isLogin) {
    if (store.state.accessLevel < 5) {
      let blacklist = ['/form', '/edit','/report']
      if (checkFromArray(to.path, blacklist)) {
        next('/no-access')
      } else {
        next()
      }
    } else if (store.state.accessLevel < 10) {
      let blacklist = ['/report']
      if (checkFromArray(to.path, blacklist)) {
        next('/no-access')
      } else {
        next()
      }
    } else {
      next()
    }
  }
  else {
    next('/login')
  }
})

const checkFromArray = (str: string, arr: Array<string>) => {
  let result = false
  arr.forEach(item => {
    if (str.search(item) > -1) {
      result = true
    } else {

    }
  })
  return result
}

export default router
