import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/logoITD.png'


const _hoisted_1 = { class: "navbar navbar-expand-lg navbar-light bg-white shadow" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  class: "collapse navbar-collapse",
  id: "navbarSupportedContent"
}
const _hoisted_4 = { class: "navbar-nav me-auto mb-2 mb-lg-0" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item dropdown" }
const _hoisted_7 = { class: "dropdown-menu" }
const _hoisted_8 = { class: "nav-item dropdown" }
const _hoisted_9 = { class: "dropdown-menu" }
const _hoisted_10 = { class: "nav-item dropdown" }
const _hoisted_11 = {
  class: "dropdown-menu",
  "aria-labelledby": "navbarScrollingDropdown"
}
const _hoisted_12 = { class: "nav-item dropdown" }
const _hoisted_13 = { class: "dropdown-menu" }
const _hoisted_14 = { class: "nav-item" }
const _hoisted_15 = { class: "nav-item" }
const _hoisted_16 = { class: "nav-item" }
const _hoisted_17 = { class: "nav-item" }
const _hoisted_18 = { class: "nav-item dropdown" }
const _hoisted_19 = {
  class: "dropdown-menu",
  "aria-labelledby": "navbarScrollingDropdown"
}
const _hoisted_20 = { class: "nav-item dropdown" }
const _hoisted_21 = { class: "dropdown-menu" }
const _hoisted_22 = { class: "nav-item" }
const _hoisted_23 = { class: "nav-item dropdown" }
const _hoisted_24 = {
  class: "dropdown-menu",
  "aria-labelledby": "navbarScrollingDropdown"
}
const _hoisted_25 = { class: "nav-item" }
const _hoisted_26 = { class: "nav-item" }
const _hoisted_27 = { class: "nav-item" }
const _hoisted_28 = { class: "nav-item" }
const _hoisted_29 = { class: "d-flex align-items-center" }
const _hoisted_30 = { class: "me-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "navbar-brand",
        to: "/login"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            class: "me-4",
            style: {"width":"2.5em","height":"2.5em"},
            src: _imports_0
          }, null, -1)
        ])),
        _: 1
      }),
      _cache[43] || (_cache[43] = _createElementVNode("button", {
        class: "navbar-toggler",
        type: "button",
        "data-bs-toggle": "collapse",
        "data-bs-target": "#navbarSupportedContent",
        "aria-controls": "navbarSupportedContent",
        "aria-expanded": "false",
        "aria-label": "Toggle navigation"
      }, [
        _createElementVNode("span", { class: "navbar-toggler-icon" })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _withDirectives(_createElementVNode("li", _hoisted_5, [
            _createVNode(_component_router_link, {
              class: "nav-link text-nowrap",
              to: "/cost"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("i", { class: "fal fa-coin" }, null, -1),
                _createElementVNode("span", { class: "ms-1" }, "Cost", -1)
              ])),
              _: 1
            })
          ], 512), [
            [_vShow, _ctx.checkAccess('Cost')]
          ]),
          _withDirectives(_createElementVNode("li", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("a", {
              class: "nav-link dropdown-toggle",
              id: "navbarScrollingDropdown",
              href: "#",
              role: "button",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false"
            }, [
              _createElementVNode("i", { class: "fal fa-tasks-alt me-1" }),
              _createTextVNode("Progress")
            ], -1)),
            _createElementVNode("ul", _hoisted_7, [
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/progress"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "fal fa-tasks mx-2" }, null, -1),
                    _createElementVNode("span", null, "Progress", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Progress')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/progress/summary/list"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("i", { class: "fal fa-tasks mx-2" }, null, -1),
                    _createElementVNode("span", null, "Progress Summary", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Progress Summary')]
              ])
            ])
          ], 512), [
            [_vShow, _ctx.checkAccess('Progress')||_ctx.checkAccess('Progress Summary')]
          ]),
          _withDirectives(_createElementVNode("li", _hoisted_8, [
            _cache[9] || (_cache[9] = _createElementVNode("a", {
              class: "nav-link dropdown-toggle",
              id: "navbarScrollingDropdown",
              href: "#",
              role: "button",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false"
            }, [
              _createElementVNode("i", { class: "fal fa-users me-1" }),
              _createTextVNode("Personnel")
            ], -1)),
            _createElementVNode("ul", _hoisted_9, [
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/manpower"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createElementVNode("i", { class: "fal fa-user mx-2" }, null, -1),
                    _createElementVNode("span", null, "Manpower", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Manpower')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/manpower/summary"
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createElementVNode("i", { class: "fal fa-clipboard-user mx-2" }, null, -1),
                    _createElementVNode("span", null, "Manpower Summary", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Manpower Summary')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/accommodation"
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createElementVNode("i", { class: "fal fa-bed-bunk mx-2" }, null, -1),
                    _createElementVNode("span", null, "Accommodation", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Accommodation')]
              ])
            ])
          ], 512), [
            [_vShow, _ctx.checkAccess('Manpower')||_ctx.checkAccess('Manpower Summary')||_ctx.checkAccess('Accommodation')]
          ]),
          _withDirectives(_createElementVNode("li", _hoisted_10, [
            _cache[19] || (_cache[19] = _createElementVNode("a", {
              class: "nav-link dropdown-toggle",
              id: "navbarScrollingDropdown",
              href: "#",
              role: "button",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false"
            }, [
              _createElementVNode("i", { class: "fal fa-tools me-1" }),
              _createTextVNode("Equipment and Tools")
            ], -1)),
            _createElementVNode("ul", _hoisted_11, [
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/pin"
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createElementVNode("i", { class: "fal fa-box-full mx-2" }, null, -1),
                    _createElementVNode("span", null, "SHEAR PIN & WIRE ROPE", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('SHEAR PIN &amp; WIRE ROPE')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/pin/summary"
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createElementVNode("i", { class: "fal fa-box-full mx-2" }, null, -1),
                    _createElementVNode("span", null, "SHEAR PIN & WIRE ROPE Summary", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('SHEAR PIN &amp; WIRE ROPE Summary')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/ptbar"
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createElementVNode("i", { class: "fal fa-box-full mx-2" }, null, -1),
                    _createElementVNode("span", null, "PT Bar", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('PT Bar')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/ptbar/summary"
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createElementVNode("i", { class: "fal fa-box-full mx-2" }, null, -1),
                    _createElementVNode("span", null, "PT Bar Summary", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('PT Bar Summary')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/jack"
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createElementVNode("i", { class: "fal fa-arrow-alt-to-top mx-2" }, null, -1),
                    _createElementVNode("span", null, "Jack", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Jack')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/jack/summary"
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createElementVNode("i", { class: "fal fa-arrow-alt-to-top mx-2" }, null, -1),
                    _createElementVNode("span", null, "Jack Summary", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Jack Summary')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/equipment"
                }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createElementVNode("i", { class: "fal fa-toolbox mx-2" }, null, -1),
                    _createElementVNode("span", null, "Equipment", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Equipment')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/equipment/summary"
                }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createElementVNode("i", { class: "fal fa-toolbox mx-2" }, null, -1),
                    _createElementVNode("span", null, "Equipment Summary", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Equipment Summary')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/steelSheet"
                }, {
                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createElementVNode("i", { class: "fad fa-file mx-2" }, null, -1),
                    _createElementVNode("span", null, "Steel Sheet", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Steel Sheet')]
              ])
            ])
          ], 512), [
            [_vShow, _ctx.checkAccess('SHEAR PIN &amp; WIRE ROPE')||_ctx.checkAccess('SHEAR PIN &amp; WIRE ROPE Summary')||_ctx.checkAccess('PT Bar')||_ctx.checkAccess('PT Bar Summary')||_ctx.checkAccess('Jack')||_ctx.checkAccess('Jack Summary')||_ctx.checkAccess('Equipment')||_ctx.checkAccess('Equipment Summary')]
          ]),
          _withDirectives(_createElementVNode("li", _hoisted_12, [
            _cache[24] || (_cache[24] = _createElementVNode("a", {
              class: "nav-link dropdown-toggle",
              id: "navbarScrollingDropdown",
              href: "#",
              role: "button",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false"
            }, [
              _createElementVNode("i", { class: "fal fa-cabinet-filing me-1" }),
              _createTextVNode("Store")
            ], -1)),
            _createElementVNode("ul", _hoisted_13, [
              _withDirectives(_createElementVNode("li", _hoisted_14, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/store"
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createElementVNode("i", { class: "fal fa-warehouse-alt mx-2" }, null, -1),
                    _createElementVNode("span", null, "Store", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Store')]
              ]),
              _withDirectives(_createElementVNode("li", _hoisted_15, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/stock"
                }, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createElementVNode("i", { class: "fal fa-boxes-alt mx-2" }, null, -1),
                    _createElementVNode("span", null, "Consumable", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Consumable')]
              ]),
              _withDirectives(_createElementVNode("li", _hoisted_16, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/stock/summary"
                }, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createElementVNode("i", { class: "fal fa-boxes-alt mx-2" }, null, -1),
                    _createElementVNode("span", null, "Consumable Summary", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Consumable Summary')]
              ]),
              _withDirectives(_createElementVNode("li", _hoisted_17, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/tool"
                }, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createElementVNode("i", { class: "fal fa-tools mx-2" }, null, -1),
                    _createElementVNode("span", null, "Tool", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Tool')]
              ])
            ])
          ], 512), [
            [_vShow, _ctx.checkAccess('Store')||_ctx.checkAccess('Consumable')||_ctx.checkAccess('Consumable Summary')||_ctx.checkAccess('Tool')]
          ]),
          _withDirectives(_createElementVNode("li", _hoisted_18, [
            _cache[34] || (_cache[34] = _createElementVNode("a", {
              class: "nav-link dropdown-toggle",
              id: "navbarScrollingDropdown",
              href: "#",
              role: "button",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false"
            }, [
              _createElementVNode("i", { class: "fal fa-tools me-1" }),
              _createTextVNode("Technicals")
            ], -1)),
            _createElementVNode("ul", _hoisted_19, [
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/budget-agreement/j"
                }, {
                  default: _withCtx(() => _cache[25] || (_cache[25] = [
                    _createElementVNode("i", { class: "fal fa-handshake-alt mx-2" }, null, -1),
                    _createElementVNode("span", null, "Budget Agreement", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Budget Agreement')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/drawing"
                }, {
                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createElementVNode("i", { class: "fal fa-compass-drafting mx-2" }, null, -1),
                    _createElementVNode("span", null, "Drawing", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Drawing')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/MSandMat/j"
                }, {
                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                    _createElementVNode("i", { class: "fal fa-box-full mx-2" }, null, -1),
                    _createElementVNode("span", null, "MS & Mat.", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('MS &amp Mat.')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/presentation/j"
                }, {
                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                    _createElementVNode("i", { class: "fal fa-box-full mx-2" }, null, -1),
                    _createElementVNode("span", null, "Presentation", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Presentation')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/monthlyProgress/j"
                }, {
                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                    _createElementVNode("i", { class: "fal fa-calendar mx-2" }, null, -1),
                    _createElementVNode("span", null, "Monthly Progress", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Monthly Progress')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/insurance/j"
                }, {
                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                    _createElementVNode("i", { class: "fal fa-person-falling-burst mx-2" }, null, -1),
                    _createElementVNode("span", null, "Insurance", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Insurance')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/pierschedules"
                }, {
                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                    _createElementVNode("i", { class: "fal fa-utility-pole mx-2" }, null, -1),
                    _createElementVNode("span", null, "Pier Schedules", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Pier Schedules')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/erection"
                }, {
                  default: _withCtx(() => _cache[32] || (_cache[32] = [
                    _createElementVNode("i", { class: "fal fa-forklift mx-2" }, null, -1),
                    _createElementVNode("span", null, "Pier Schedules (Erection)", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Pier Schedules (Erection)')]
              ]),
              _withDirectives(_createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/lgspecifications/lg"
                }, {
                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                    _createElementVNode("i", { class: "fa-light fa-bridge-circle-check mx-2" }, null, -1),
                    _createElementVNode("span", null, "LG Specifications", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('LG Specifications')]
              ])
            ])
          ], 512), [
            [_vShow, _ctx.checkAccess('Budget Agreement')||_ctx.checkAccess('Drawing')||_ctx.checkAccess('Submission')||_ctx.checkAccess('Presentation')||_ctx.checkAccess('Monthly Progress')||_ctx.checkAccess('Insurance')||_ctx.checkAccess('Pier Schedules (Erection)')||_ctx.checkAccess('Bearing')||_ctx.checkAccess('LG Specifications')||_ctx.checkAccess('Pier Schedules')||_ctx.checkAccess('LG Specifications')]
          ]),
          _withDirectives(_createElementVNode("li", _hoisted_20, [
            _cache[36] || (_cache[36] = _createElementVNode("a", {
              class: "nav-link dropdown-toggle",
              id: "navbarScrollingDropdown",
              href: "#",
              role: "button",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false"
            }, [
              _createElementVNode("i", { class: "fal fa-toolbox me-1" }),
              _createTextVNode("Contractor")
            ], -1)),
            _createElementVNode("ul", _hoisted_21, [
              _withDirectives(_createElementVNode("li", _hoisted_22, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/supplier/j"
                }, {
                  default: _withCtx(() => _cache[35] || (_cache[35] = [
                    _createElementVNode("i", { class: "fal fa-box-full mx-2" }, null, -1),
                    _createElementVNode("span", null, "Sub Contractor & Supplier", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Sub Contractor &amp; Supplier')]
              ])
            ])
          ], 512), [
            [_vShow, _ctx.checkAccess('Sub Contractor &amp; Supplier')]
          ]),
          _withDirectives(_createElementVNode("li", _hoisted_23, [
            _cache[40] || (_cache[40] = _createElementVNode("a", {
              class: "nav-link dropdown-toggle",
              id: "navbarScrollingDropdown",
              href: "#",
              role: "button",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false"
            }, [
              _createElementVNode("i", { class: "fal fa-user-lock me-1" }),
              _createTextVNode("Admin")
            ], -1)),
            _createElementVNode("ul", _hoisted_24, [
              _withDirectives(_createElementVNode("li", _hoisted_25, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/code"
                }, {
                  default: _withCtx(() => _cache[37] || (_cache[37] = [
                    _createElementVNode("i", { class: "fal fa-file" }, null, -1),
                    _createElementVNode("span", { class: "ms-1" }, "Code", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Code')]
              ]),
              _withDirectives(_createElementVNode("li", _hoisted_26, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/job"
                }, {
                  default: _withCtx(() => _cache[38] || (_cache[38] = [
                    _createElementVNode("i", { class: "fal fa-file" }, null, -1),
                    _createElementVNode("span", { class: "ms-1" }, "Job", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('Job')]
              ]),
              _withDirectives(_createElementVNode("li", _hoisted_27, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-nowrap",
                  to: "/user"
                }, {
                  default: _withCtx(() => _cache[39] || (_cache[39] = [
                    _createElementVNode("i", { class: "fal fa-users" }, null, -1),
                    _createElementVNode("span", { class: "ms-1" }, "User", -1)
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.checkAccess('User')]
              ])
            ])
          ], 512), [
            [_vShow, _ctx.checkAccess('Code')||_ctx.checkAccess('Job')||_ctx.checkAccess('User')]
          ]),
          _withDirectives(_createElementVNode("li", _hoisted_28, [
            _createVNode(_component_router_link, {
              class: "nav-link text-nowrap",
              to: "/report"
            }, {
              default: _withCtx(() => _cache[41] || (_cache[41] = [
                _createElementVNode("i", { class: "fal fa-analytics" }, null, -1),
                _createElementVNode("span", { class: "ms-1" }, "Report", -1)
              ])),
              _: 1
            })
          ], 512), [
            [_vShow, _ctx.checkAccess('Report')]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("span", _hoisted_30, "Hello, " + _toDisplayString(_ctx.store.state.remember.username), 1),
        (_ctx.store.state.isLogin)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "btn btn-outline-danger text-danger",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
            }, _cache[42] || (_cache[42] = [
              _createElementVNode("span", null, "Logout", -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}