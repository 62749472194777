import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3 mt-4" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row mt-3" }
const _hoisted_5 = { class: "col-sm-8 mb-2" }
const _hoisted_6 = { class: "col-sm-4 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Material Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label" }, "Name", -1)),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.name) = $event))
          }, null, 512), [
            [_vModelText, _ctx.doc.name]
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, "Unit", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.unit) = $event))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("option", null, "Liter", -1),
            _createElementVNode("option", null, "Kg", -1),
            _createElementVNode("option", null, "Ea", -1)
          ]), 512), [
            [_vModelSelect, _ctx.doc.unit]
          ])
        ])
      ]),
      _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"row g-3 d-flex justify-content-center\"><div class=\"col-5\"><button class=\"w-100 btn btn-secondary text-white btn-lg mt-4\">ย้อนกลับ</button></div><div class=\"col-5\"><button class=\"w-100 btn btn-primary btn-lg mt-4 text-white\" id=\"save\" type=\"submit\">บันทึก</button></div></div>", 1))
    ], 32)
  ]))
}