
import { Options, Vue } from "vue-class-component";
import address, { filePath } from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Form extends Vue {
  doc: any = {};
  store = useStore();
  filePath = filePath;
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  fetchData() {
    axios.get(`${address}/store/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
    });
  }
  submitForm() {
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/store/${this.doc._id}`, this.doc)
        .then((response) => {
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/store");
        });
    } else {
      axios
        .post(`${address}/store`, {
          ...this.doc,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/store");
        });
    }
  }
}
